.page_container {
  width: 100%;
  min-height: 100%;

  display: flex;
  flex-direction: column;
}

.publish_top {
  width: 100%;
  height: 312px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #0da57719;

  & > .logo {
    margin-top: 46px;
    width: 100px;
    height: 24px;

    object-fit: contain;
  }

  & > .prompt {
    margin: 0 120px;

    text-align: center;
    font-size: 16px;
    line-height: 19px;
    font-weight: normal;
    color: black;

    &.above {
      margin-top: 70px;
    }

    &.below {
      margin-top: 20px;
    }
  }

  & > .submit_form {
    margin-top: 25px;

    display: flex;
    flex-direction: row;

    & > input[type="text"] {
      margin: 0;
      box-sizing: border-box;
      width: 540px;
      height: 50px;

      padding: 17px;

      font-size: 14px;
      line-height: 16px;

      border: 2px solid #d2d4d9;
      border-radius: 3px;
      color: black;

      &:placehoder {
        color: #828282;
      }
    }

    & > input[type="submit"] {
      margin-left: 8px;
      box-sizing: border-box;
      width: 109px;
      height: 50px;

      font-size: 16px;
      line-height: 19px;
      font-weight: 500;

      border: none;
      border-radius: 3px;
      color: white;
      background: #0da577;

      cursor: pointer;
    }
  }
}

.error_list {
  display: block;
  margin: 30px 100px;
}

.publish_bottom {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  & > h2 {
    margin-top: 40px;
    margin-bottom: 0;

    font-size: 30px;
    line-height: 35px;
    font-weight: normal;

    color: black;
  }

  & > .steps {
    margin: 60px 77px 0;

    display: flex;
    flex-direction: row;

    & > div {
      flex-grow: 1;
      flex-basis: 0;

      &:not(:first-child) {
        margin-left: 65px;
      }

      & > h4 {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        text-transform: uppercase;

        color: #828282;
      }

      & > .subtitle {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;

        color: #333333;
      }

      & > .content {
        font-size: 14px;
        line-height: 20px;

        color: #333333;

        & a {
          color: #333333;
        }
      }
      
    }
  }
}
